import React from "react"
import Layout from "@components/layout";

// Import SVG
import topCenterLogo from '@images/logo_center.svg';
import corp_topbn1 from '@images/top_bn01.webp';
import corp_topbn2 from '@images/top_bn02.webp';
import corp_topbn3 from '@images/top_bn03.webp';
import topmissIcon from '@images/top_icon_img.svg';
import media_img01 from '@images/top_media_img01.webp';

// 関数呼び出し
import { SeoContents } from "@components/seo-data.js" // Seo情報設定

const Index = () => {
  const pageBUrl = '/business';
  const pageFXUrl = '/kaigai-fx/';

  // Seo情報設定
  const pathName = 'index';
  const seoTag = SeoContents({ Path:pathName });

  return (
  <>
    <Layout>
      {seoTag}
      <div className={pathName}> 
        {/* FV */}
        <div className="MV_top ">
          <div className="md:w-full max-w-4xl mx-auto  md:mb-[60px] mb-[30px] py-[200px] text-left  md:px-0 px-5">
            <h1 className="text-4xl text-white mb-5">Good At Trading</h1>
            <p className="text-[13px] text-white">届けたい人に、届けたい情報を<br/>快適な環境で利用してもらうために<br/>
            デジタルマーケティング支援を行なっております。
            </p>
          </div>
        </div>

        <div>
          <div className="text-center mb-[50px] md:mt-[30px] mt-[50px] "> 
            <h2 className="font-semibold tracking-wide text-lg md:text-xl">Business</h2>

          </div>
          <div className="md:flex md:justify-between  max-w-xl mx-auto  md:max-w-4xl px-6 flex-wrap corp_thum">
            <div className="ob_thum">
   
              <p className="text-[13px] mt-2 text-center text-white">蓄積したノウハウを利用した<br/>自社オウンドメディアの運営</p>
            </div>
            <div className="ob_thum">

              <p className="text-[13px] mt-2 text-center text-white">効果的なsns利用とweb広告の運用<br/>デジタルマーケティング支援</p>
            </div>
            <div className="ob_thum">
              <p className="text-[13px] mt-2 text-center text-white">蓄積したノウハウを利用した<br/>自社オウンドメディアの運営</p>
            </div>
          </div>
         <div className="pb-14 pt-4 md:pt-14  md:pb-[80px]">
             {/*<a href={pageBUrl} className="rounded block mx-auto max-w-xs text-center text-[13px] text-white font-bold py-5 px-16 my-2 main_btn">VIRE MORE</a>   */}
          </div>
       
        </div>

        <div className="MV_cont">
          <div className="text-center mb-[60px] "> 
            <h2 className="pt-[80px] font-semibold tracking-wide text-lg md:text-xl text-white">Mission</h2>

          </div>
        
          <div className="w-[90%] mx-auto">
            <h3 className="text-[20px] mt-2 text-center  text-white">利益が循環する社会作りのために</h3>
            <p className="text-[13px] mt-5 text-center  text-white">届けたい人に、届けたい情報を<br/>快適な環境で利用してもらうために<br/>
            私たちはクライアントの期待以上の結果にコミットすることを目指して<br/>自社のプロジェクトで得られた最新のノウハウを還元します。
            </p>
          </div>
          {/* すべてを見る */}
          <div className="py-14 md:pb-[80px]">
            {/*<a href={pageBUrl} className="rounded block mx-auto max-w-xs text-center text-[13px] text-white font-bold py-5 px-16 my-2 main_btn">VIRE MORE</a>*/}
          </div>
        </div>  

        <div>
          <div className="text-center mb-[60px]"> 
            <h2 className="pt-[80px] font-semibold tracking-wide text-lg md:text-xl ">Media</h2>
          </div>
          <div className="md:flex max-w-xs md:max-w-4xl mx-auto">
            <div>
              <a href={pageFXUrl} aria-label="海外FX200"><img src={media_img01} alt="" width="382" height="212"/></a>
            </div>
            <div className="md:pl-5 md:mt-0 mt-[30px] md:w-[65%]">
              <h3 className="text-[14px]  font-semibold leading-6">Good At Trading</h3>
              <p className="md:mt-5 mt-5 font-semibold text-[17px] ">海外FX業者の比較やブローカー別に特徴をまとめた 比較サイトです。</p>
              <p className="text-[13px] mt-2">これからFXを始めたいと思っているビギナーの方からすでに取引を行っている経験者のために、各種お役立ち情報を更新してまいります。</p>
              <p className="mt-5 text-xs mb-20"><a href={pageFXUrl}>ページはこちら</a></p>
            </div>
          </div>
        </div>

      </div>
    </Layout> 
  </>
  )
}
export default Index

